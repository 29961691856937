import { useEffect } from 'react';
import Head from 'next/head';

import '../styles/globals.scss';
import ensureFirebase from '../support/ensureFirebase';
ensureFirebase();
import PlausibleProvider from 'next-plausible';

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    document.body.className = pageProps.isSizeConstrained ? 'size-constrained' : '';
  });
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Head>
      <PlausibleProvider domain="makeandplay.com">
        <Component {...pageProps} />
      </PlausibleProvider>
    </>
  );
}

export default MyApp
