import { getApps, initializeApp } from "firebase/app";

export default function ensureFirebase() {
  const apps = getApps();
  if (apps.length === 0) {
    return initializeApp({
      apiKey: "AIzaSyDgjqw1jdCwzpwduiQdHpFmYDELjdoC7RU",
      authDomain: "toy-creator.firebaseapp.com",
      projectId: "toy-creator",
      storageBucket: "toy-creator.appspot.com",
      messagingSenderId: "552801789166",
      appId: "1:552801789166:web:64e89f3faa4c84eef9bfff",
    });
  } else {
    return apps[0];
  }
}